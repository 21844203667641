const matomoProxyUrl = process.env.MAMOTO_PROXY_URL;
const matomoProxyScript = process.env.MAMOTO_PROXY_SCRIPT;
const matomoSiteId = process.env.MATOMO_SITE_ID;

export const MatomoEmbedCode = ({ customDimensions = {} }) => {
    translateDimensionKeys(customDimensions);
    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `
                    var waitForTrackerCount = 0;
                    function matomoWaitForTracker() {
                        if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
                        if (waitForTrackerCount < 40) {
                            setTimeout(matomoWaitForTracker, 250);
                            waitForTrackerCount++;
                            return;
                        }
                        } else {
                        window.addEventListener('CookiebotOnAccept', function (e) {
                            consentSet();
                        });
                        window.addEventListener('CookiebotOnDecline', function (e) {
                            consentSet();
                        })
                        }
                    }
                    function consentSet() {
                        if (Cookiebot.consent.statistics) {
                        _paq.push(['rememberCookieConsentGiven']);
                        _paq.push(['setConsentGiven']);
                        } else {
                        _paq.push(['forgetCookieConsentGiven']); 
                        _paq.push(['deleteCookies']);
                        }
                    }
                    document.addEventListener('DOMContentLoaded', matomoWaitForTracker);
                    
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['requireCookieConsent']);
                    ${Object.values(customDimensionKeyMap).map((index) => {
                        return `_paq.push(['deleteCustomDimension', ${index}])`;
                    }).join('\n')}
                    _paq.push(['trackPageView', document.title, ${JSON.stringify(customDimensions)}]);
                    _paq.push(['trackVisibleContentImpressions', true, 750]);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                        var u="${matomoProxyUrl}";
                        _paq.push(['setTrackerUrl', u+'${matomoProxyScript}']);
                        _paq.push(['setSiteId', '${matomoSiteId}']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.async=true; g.src=u+'${matomoProxyScript}'; s.parentNode.insertBefore(g,s);
                    })();
                `,
            }}
        />
    )
};

const customDimensionKeyMap = {
    'employer_id': 1,
    'page_type': 2,
}

const translateDimensionKey = (customDimensionName) => {
    return customDimensionKeyMap[customDimensionName];
}

/**
 * Convert object keys to custom dimension ids
 * @param {*} customDimensions 
 */
const translateDimensionKeys = (customDimensions) => {
    if (customDimensions) {
        const keys = Object.keys(customDimensions);
        keys.forEach((key) => {
            const index = translateDimensionKey(key);
            if (index) {
                customDimensions[`dimension${index}`] = customDimensions[key];
                delete customDimensions[key];
            }
        });
    }
}

export const trackEvent = (category, action, name, value, customDimension) => {
    if (typeof _paq !== 'undefined') {
        translateDimensionKeys(customDimension);
        _paq.push(['trackEvent', category, action, name, value, customDimension]);
    }
}

export const setCustomDimension = (customDimensionName, value) => {
    if (typeof _paq !== 'undefined' && index && value) {
        _paq.push(['setCustomDimension', translateDimensionKey(key), value]);
    }
}

export const handlePageChange = (url, documentTitle, customDimensions) => {
    if (typeof _paq !== 'undefined') {
        translateDimensionKeys(customDimensions);
        trackPageView(url, documentTitle, customDimensions);
        _paq.push(['deleteCustomVariables', 'page']);

        const content = document.querySelector('.page-content') || document;
        _paq.push(['trackContentImpressionsWithinNode', content]);
        // _paq.push(['MediaAnalytics::scanForMedia', content]);
        // _paq.push(['FormAnalytics::scanForForms', content]);
        _paq.push(['enableLinkTracking']);
    }
}

export const trackPageView = (url, documentTitle, customDimensions) => {
    if (typeof _paq !== 'undefined') {
        _paq.push(['setCustomUrl', url]);
        _paq.push(['setDocumentTitle', documentTitle])
        Object.values(customDimensionKeyMap).forEach((index) => {
            _paq.push(['deleteCustomDimension', index]);
        });
        _paq.push(['trackPageView', documentTitle, customDimensions]);
    }
}

export const trackContentImpression = (contentName, contentPiece, contentTarget) => {
    if (typeof _paq !== 'undefined') {
        _paq.push(['trackContentImpressions', contentName, contentPiece, contentTarget]);
    }
}

export const trackContentInteraction = (contentName, contentPiece, contentTarget) => {
    if (typeof _paq !== 'undefined') {
        _paq.push(['trackContentInteraction', contentName, contentPiece, contentTarget]);
    }
}

export const generateEmployerRelatedContentName = (employer) => {
    return `employer_profile_${employer?.uid}`;
}
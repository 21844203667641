import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import DefaultErrorPage from "next/error";
import Head from "next/head";
import ContentApiService from "../modules/cms_content/services/ContentApiService";
import { getLayout } from "../layouts/CMSPageLayout";
import ContentLibs from "../modules/cms_content/libs/ContentLibs";
import ServersidePageUtility from "../libs/ServersidePageUtility";
import Spinner from "../components/Spinner";
import PageRenderLib from "../modules/cms_content/libs/PageRenderLib";
import getConfig from "next/config";
import { getUserProfilePageProps } from "../modules/user/lib/PagePropsUtility";
import { PageContext } from "../modules/cms_content/context/PageContext";
import { getEpisodes } from "../modules/articles/services/PodcastApiService";
import PagePathUtility from "../libs/PagePathUtility";

const STATE_OK = 200;
const STATE_PAGE_NOT_FOUND = 404;
const STATE_ERROR = 503;

const Page = ({ page, navigation, state, pageContents, userProfileData }) => {
  const { setAdditionalData } = useContext(PageContext);
  const router = useRouter();

  useEffect(() => {
    userProfileData && setAdditionalData({ userProfileData: userProfileData });
  }, []);

  if (router.isFallback) {
    return <Spinner message="Loading ..." />;
  }

  // in case of fallback mode is active we filter requests to static files
  // which will land here because file not exits
  if (state !== STATE_OK) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <DefaultErrorPage statusCode={state} />
      </>
    );
  }

  const pageContent = PageRenderLib.renderPage(page, pageContents);

  return <>{pageContent}</>;
};

/**
 * Get static props of page
 *
 * @param params
 * @param preview
 * @param previewData
 * @param req
 * @returns {Promise.<{props, contents: Array}>}
 */
export async function getStaticProps({
  params,
  preview = false,
  previewData = {},
  req = {},
}) {
  const { publicRuntimeConfig } = getConfig();
  let state = STATE_OK;

  // get current path to check against cms
  const path = ContentLibs.getPathFromSlug(params);

  // in case of fallback mode is active we filter requests to static files
  // which will land here because file not exits
  const ignorePaths = publicRuntimeConfig.rootCatchAllIgnorePaths;
  for (let i = 0; i < ignorePaths.length; i++) {
    if (path.startsWith(ignorePaths[i])) {
      state = STATE_PAGE_NOT_FOUND;
      break;
    }
  }

  let pageProps = {};

  if (state === STATE_OK) {
    // fetch page properties and contents
    try {
      const defaultPageProps = await ServersidePageUtility.defaultPageProps(
        path
      );

      const { pageFound } = defaultPageProps;
      if (!pageFound) {
        return {
          notFound: true,
        };
      }

      // check if page should be redirected to employer profile
      const { redirectType, redirectPageSlug } = defaultPageProps?.page || {};
      if (redirectType === "employerProfile" && redirectPageSlug) {
        const employerDetailLink = PagePathUtility.getPathConfig(
          "employerDetail",
          { pathSegment: redirectPageSlug }
        );

        return {
          redirect: {
            destination: employerDetailLink,
            statusCode: 301,
          },
        };
      }

      // build page properties from fetched data
      pageProps = {
        ...defaultPageProps,
      };

      if (
        defaultPageProps?.pageContents?.mainColumn?.contents?.some((item) => {
          return (
            item.CType === "mask_registration_form" ||
            (item.CType.startsWith("container_") &&
              item.contentParts.column_0 &&
              Object.keys(item.contentParts)
                .filter((key) => key.startsWith("column_"))
                .some((columnKey) =>
                  item.contentParts[columnKey]?.contents?.some(
                    (containerItem) =>
                      containerItem.CType === "mask_registration_form"
                  )
                ))
          );
        })
      ) {
        const userProfilePageProps = await getUserProfilePageProps();
        pageProps = {
          ...pageProps,
          userProfileData: userProfilePageProps,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  return {
    props: {
      preview,
      previewData,
      state,
      ...pageProps,
      customDimensions: {
        page_type: "content_page",
      },
    },
    revalidate: publicRuntimeConfig.pageRevalidationConfig.cmsPages,
  };
}

/**
 * Get static paths
 *
 * @returns {Promise.<{paths: Array, fallback: boolean}>}
 */
export async function getStaticPaths() {
  let paths = [];
  try {
    const nextJsPages = ServersidePageUtility.getNextJsPages();
    const allPages = await ContentApiService.fetchMenu(
      "staticPathsForAppBuild",
      nextJsPages
    );
    paths = ContentLibs.getAllPathsOfMenu(allPages.result.menu);

    /// ////////////////////////////////////////////////////////////////
    // remove existing next.js pages (/pages/*)

    paths = paths.filter((pathItem) => {
      const path = ContentLibs.getPathFromSlug(pathItem.params);
      return !nextJsPages.includes(path);
    });

    // END: remove existing next.js pages (/pages/*)
    /// ////////////////////////////////////////////////////////////////

    // TODO remove workaround. Add root page to page tree api request
    paths.push({
      params: {
        slug: [],
      },
    });
  } catch (e) {
    console.log("Error when fetching the menu structure.");
    console.log(e);
  }

  return {
    paths,
    fallback: "blocking",
  };
}

Page.getLayout = getLayout;

export default Page;
